import React from "react";
import "./Footer.css";
import Logo from "../../assets/images/LOGO.png";

function Footer() {
  return (
    <div className="footer-container">
      <div className="container mx-auto pt-12 pb-12">
        <div className="xl:flex xl:flex-wrap xl:text-left text-center justify-center gap-10">
          <ul className="text-yellow-400 uppercase">
            <h1 className="font-bold uppercase text-2xl 2xl:mb-8 2xl:mt-0 mt-8 mb-4 text-white">
              Nova
            </h1>
            <li>
              <a href="/home">Play now</a>
            </li>
            <li>
              <a href="/home">Vote</a>
            </li>
            <li>
              <a href="/home">Store</a>
            </li>
            <li>
              <a href="/home">Sign up</a>
            </li>
            <li>
              <a href="/home">Login</a>
            </li>
          </ul>
          <ul className="text-yellow-400 uppercase">
            <h1 className="font-bold uppercase text-2xl 2xl:mb-8 2xl:mt-0 mt-8 mb-4 text-white">
              Community
            </h1>
            <li>
              <a href="/home">Forums</a>
            </li>
            <li>
              <a href="/home">Hiscores</a>
            </li>
            <li>
              <a href="/home">Discord</a>
            </li>
          </ul>
          <img src={Logo} alt="logo" className="xl:m-0 m-auto" />
          <ul className="text-yellow-400 uppercase">
            <h1 className="font-bold uppercase text-2xl 2xl:mb-8 2xl:mt-0 mt-8 mb-4 text-white">
              Support
            </h1>
            <li>
              <a href="/home">Submit ticket</a>
            </li>
            <li>
              <a href="/home">Live support</a>
            </li>
            <li>
              <a href="/home">Discord</a>
            </li>
          </ul>
          <ul className="text-yellow-400 uppercase">
            <h1 className="font-bold uppercase text-2xl 2xl:mb-8 2xl:mt-0 mt-8 mb-4 text-white">
              Socials
            </h1>
            <li>
              <a href="/home">Youtube</a>
            </li>
            <li>
              <a href="/home">Facebook</a>
            </li>
            <li>
              <a href="/home">Twitter</a>
            </li>
            <li>
              <a href="/home">Instagram</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Footer;
