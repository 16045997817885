import React, { useEffect, useState } from "react";
import "./News.css";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import BlockContent from "@sanity/block-content-to-react";
import client from "../../client";

function News() {
  const [discordWidget, setDiscordWidget] = useState("");
  const [news, setNews] = useState("");
  const [youtubeWidget, setYoutubeWidget] = useState("");
  const [mediaPosts, setMediaPosts] = useState([]);
  useEffect(() => {
    client
      .fetch(
        `
      *[ _type == "newsSection"] {
      newsAndAnnouncements,
      youtubeWidget,
      discordWidget
      }`
      )
      .then((data) => {
        setDiscordWidget(data[0].discordWidget);
        setYoutubeWidget(data[0].youtubeWidget);
        setNews(data[0].newsAndAnnouncements);
      })
      .catch(console.error);
    client
      .fetch(
        `
      *[ _type == "mediaSection"] {
        mediaTitle,
        mediaDesc,
        mediaImage {
          asset -> {
            url
          },
        }
      }`
      )
      .then((data) => {
        setMediaPosts(data);
        console.log(data);
      })
      .catch(console.error);
  }, []);
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  return (
    <div className="news-container border-b border-b-gray-400 relative">
      <div className="flex flex-wrap justify-center gap-10 pt-10 pb-10 container mx-auto">
        <div className="left-side max-w-3xl">
          <div className="box bg-[#202225] rounded-lg">
            <h1 className="text-yellow-500 text-2xl font-bold uppercase p-6 shadow-[inset_0_0_25px_10px_rgba(0,0,0,0.6)]">
              News & Announcements
            </h1>
            <hr className="border border-yellow-600" />
            <p className="p-8 overflow-y-scroll h-[250px]">
              <BlockContent blocks={news} />
            </p>
          </div>
          <div className="box bg-[#202225] rounded-lg mt-12">
            <h1 className="text-yellow-500 text-2xl font-bold uppercase p-6 shadow-[inset_0_0_25px_10px_rgba(0,0,0,0.6)]">
              Video of the week
            </h1>
            <hr className="border border-yellow-600" />
            <iframe
              title="yt-video"
              src={youtubeWidget}
              width="100%"
              height="315"
            ></iframe>
          </div>
        </div>
        <div className="right-side">
          <iframe
            title="discord"
            src={discordWidget}
            width="313"
            height="500"
            allowtransparency="true"
            frameBorder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
          <div className="play-container text-center p-6 mt-10">
            <h1 className="text-4xl text-yellow-400 uppercase">Play now</h1>
            <h2 className="uppercase">
              With <span className="text-yellow-400">64</span> others!
            </h2>
          </div>
          <div className="play-container text-center p-6 mt-4">
            <h1 className="text-yellow-400 uppercase font-thin text-sm">
              Top supporter of the month
            </h1>
            <h2 className="text-2xl mt-2 uppercase">Fiddle</h2>
          </div>
        </div>
        <div className="break basis-full"></div>
        <div className="media-gallery w-full md:pl-48 md:pr-48 pl-4 pr-4 pb-10 text-center m-auto">
          <h1 className="text-yellow-500 text-2xl font-bold uppercase p-6 shadow-[inset_0_0_25px_10px_rgba(0,0,0,0.6)] bg-[#202225]">
            Media gallery
          </h1>
          <hr className="border border-yellow-600" />
          <Carousel responsive={responsive} infinite={true} className="mt-8">
            {mediaPosts.map((element) => {
              return (
                <div
                  key={element.mediaTitle}
                  className="shadow-[inset_0_0_25px_10px_rgba(0,0,0,0.6)] ml-2 mr-2 text-left"
                >
                  <img
                    className="w-full border border-yellow-500"
                    src={element.mediaImage.asset.url}
                    alt="slide"
                  />
                  <h1 className="mt-4 text-2xl">{element.mediaTitle}</h1>
                  <BlockContent blocks={element.mediaDesc} />
                </div>
              );
            })}
          </Carousel>
        </div>
      </div>
      <span className="section-divider"></span>
    </div>
  );
}

export default News;
