import React, { useEffect, useState } from "react";
import Logo from "../../assets/images/LOGO.png";
import "./Header.css";
import client from "../../client";

function Header() {
  const [links, setLinks] = useState({
    home: "",
    store: "",
    forums: "",
    hiscores: "",
    vote: "",
    support: "",
    playnow: "",
  });
  useEffect(() => {
    client
      .fetch(
        `
      *[ _type == "navigation"] {
      home,
      store,
      forums,
      hiscores,
      vote,
      support,
      playnow
      }`
      )
      .then((data) => setLinks(data[0]))
      .catch(console.error);
  }, []);

  return (
    <>
      <div className="header-container">
        <div className="upper-nav"></div>
        <div className="logo-section">
          <img src={Logo} alt="Logo" className="m-auto" />
        </div>
        <div className="nav-bar">
          <nav>
            <ul className="nav-ul hidden flex-wrap gap-24 justify-center uppercase font-bold border-t border-b border-yellow-400 pt-6 pb-6 lg:flex">
              <li>
                <a href={links.home}>Home</a>
              </li>
              <li>
                <a href={links.store}>Store</a>
              </li>
              <li>
                <a href={links.forums}>Forums</a>
              </li>
              <li>
                <a href={links.hiscores}>Hiscores</a>
              </li>
              <li>
                <a href={links.support}>Support</a>
              </li>
              <li>
                <a href={links.playnow}>Play now</a>
              </li>
              <li>
                <a href={links.vote}>Vote now</a>
              </li>
            </ul>
            <ul className="lg:hidden block font-bold text-2xl text-center uppercase bg-[#202225] p-4 border border-yellow-500 shadow-[inset_0_0_25px_10px_rgba(0,0,0,0.6)]">
              <li>
                <a href={links.home}>Home</a>
              </li>
              <li className="mt-8">
                <a href={links.store}>Store</a>
              </li>
              <li className="mt-8">
                <a href={links.forums}>Forums</a>
              </li>
              <li className="mt-8">
                <a href={links.hiscores}>Hiscores</a>
              </li>
              <li className="mt-8">
                <a href={links.support}>Support</a>
              </li>
              <li className="mt-8">
                <a href={links.playnow}>Play now</a>
              </li>
              <li className="mt-8">
                <a href={links.vote}>Vote now</a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Header;
