import React, { useEffect, useState } from "react";
import "./Welcome.css";
import client from "../../client";
import NovaEmblem from "../../assets/images/Welcome-logo.png";
import BlockContent from "@sanity/block-content-to-react";

function Welcome() {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  useEffect(() => {
    client
      .fetch(
        `
      *[ _type == "welcomeSection"] {
      welcomeToNovaTitle,
      welcomeToNovaDesc
      }`
      )
      .then((data) => {
        setTitle(data[0].welcomeToNovaTitle);
        setDescription(data[0].welcomeToNovaDesc);
      })
      .catch(console.error);
  }, []);
  return (
    <>
      <div className="welcome-container flex flex-wrap gap-10 justify-center items-center border-b border-b-gray-400 relative pt-10 pb-10">
        <div className="left-side p-8 max-w-3xl">
          <img src={NovaEmblem} alt="emblem" />
        </div>
        <div className="right-side max-w-3xl p-8">
          <h1 className="font-bold text-4xl">{title}</h1>
          <p className="mt-4">{<BlockContent blocks={description} />}</p>
        </div>
        <span className="section-divider"></span>
      </div>
    </>
  );
}

export default Welcome;
