import "./App.css";
import Header from "./components/Header/Header";
import News from "./components/News/News";
import Welcome from "./components/Welcome/Welcome";
import Footer from "./components/Footer/Footer";
import { TabTitle } from "./TabTitle";
import client from "./client";
import React, { useEffect, useState } from "react";

function App() {
  const [title, setTitle] = useState("");
  useEffect(() => {
    client
      .fetch(
        `
      *[ _type == "homePage"] {
        pageTitle,
      }`
      )
      .then((data) => {
        setTitle(data[0].pageTitle);
      })
      .catch(console.error);
  }, []);
  TabTitle(title);

  return (
    <div className="App">
      <Header />
      <Welcome />
      <News />
      <Footer />
    </div>
  );
}

export default App;
